//レビューへのアンカースクロール
$(function() {
  var headerH = $(".sticky-top").height();
  $("#menu_bunner>a:first-of-type()").click(function() {
      var position = $('#review').offset().top - headerH;
      $("html, body").animate({ scrollTop: position }, 500);
  });
});

//Local Servicesの配置
$(window).on( "load resize turbolinks:load", function() {
  var windowWidth = window.innerWidth;
  if(windowWidth >= 992){
    var localServices = $('#best-cars>div').outerHeight();
    var SearchByMake = $('#banner-area').outerHeight();    
    $('#local-services').css('top', localServices + 16 + 'px');
    $('#search-by-make').css('top', (-1 * SearchByMake) + 8 + 'px');
  }else{
    $('#local-services').css('top', 'unset');
    $('#search-by-make').css('top', 'unset');
  }
  return false;
})